<template>
  <v-container
    class="products-list-page"
    fluid>
    <img-popup-preview
      v-model="previewEnabled"
      :img-src="currentImgPreview" />
    <v-row
      class="head-search">
      <v-col
        cols="6"
        sm="9"
        md="6"
        lg="auto"
        xl="auto">
        <v-btn
          depressed
          color="white"
          class="elevation-3"
          :loading="loading"
          @click="exportProductsToCSV()">
          <img
            src="@/assets/image/Akita-64x64.png"
            alt="Akita-Logo"
            width="20">
          <span class="body-2 ml-1">
            Export Akita ({{ totalProduct }})
          </span>
        </v-btn>
      </v-col>
      <!-- <v-col
        cols="6"
        sm="3"
        md="6"
        lg="auto"
        xl="auto"
        :class="$vuetify.breakpoint.md || isMobile ? 'text-right' : ''"
      >
        <v-btn
          depressed
          color="primary"
          to="/product/create">
          <span
            v-if="isMobile"
            class="body-2 ml-1">
            NEW PRODUCT
          </span>
          <span
            v-else
            class="body-2 ml-1">
            ADD NEW PRODUCT
          </span>
        </v-btn>
      </v-col> -->
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        lg="8"
        xl="6">
        <search-by-tags-and-brand
          v-model="query"
          append-icon="mdi-magnify"
          tags-width="300px"
          show-collection
          :is-mobile="isMobile"
          @on-search="searchProduct()" />
      </v-col>
    </v-row>

    <v-row
      v-for="(product, index) in products"
      :key="`product-${index}`"
      class="white elevation-3 my-8"
      :class="isMobile ? `mx-2` : ''">
      <v-col :cols="12">
        <product
          :product-id="product.id"
          :product-name="product.name"
          :is-sold-out="checkIsOutOfStock(product)"
          :model="product.model"
          :brand="product.brand"
          :skus="product.skus"
          :gw-collection="product.gwCollection"
          :categories="product.categories"
          :product-prototype="product.productPrototype || null"
          :group-categories="product.groupCategories"
          :tags="product.tags"
          :photo-urls="product.photoUrls"
          :alias-id="product.aliasId"
          :warehouses="mapWarehouse"
          :bc-sync="product.bcSync"
          :is-mobile="isMobile"
          @on-preview-image="previewImg($event)" />
      </v-col>
    </v-row>

    <v-row
      v-if="isNotHaveProducts"
      justify="center"
      align="center">
      <v-col
        cols="auto"
        class="my-15">
        <p>There aren't any products. :( </p>
      </v-col>
    </v-row>

    <v-skeleton-loader
      v-for="loader in loaders"
      :key="`loader-${loader}`"
      :loading="true"
      height="150"
      class="loader-product-list"
      type="list-item-avatar-three-line" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import ProductProvider from '@/resources/ProductProvider'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import ImgPopupPreview from '@/components/ImgModalPreview.vue'
import ConvertQueryStringToArray from '@/assets/js/ConvertQueryStringToArray'
import Product from '../components/ProductBox.vue'

const ProductService = new ProductProvider()

export default {
  name: 'ProductList',
  components: {
    product: Product,
    ImgPopupPreview,
    SearchByTagsAndBrand
  },
  data () {
    return {
      previewEnabled: false,
      currentImgPreview: null,
      products: [],
      query: {
        page: 0,
        limit: 15,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all',
        collection: ''
      },
      totalPage: 1,
      totalProduct: 0,
      loading: false,
      getting: false,
      isLoadComplete: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      warehouses: 'Store/warehouse'
    }),
    isNotHaveProducts () {
      return this.products.length === 0 && this.isLoadComplete
    },
    loaders () {
      return this.isLoadComplete ? 0 : 2
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    this.queryTags()
    this.fetchProducts()
    this.$store.dispatch('Store/getWarehouse')
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage'
    }),
    checkIsOutOfStock (product) {
      let result = true
      const warehouses = this.warehouses.filter((w) => w.status === 'active' && +w.id !== 0)
      for (const sku of product.skus) {
        for (const st of sku.stock) {
          const warehouseStock = warehouses.find((wh) => +wh.id === +st.warehouse.id)
          if (warehouseStock) {
            const offlineMinStock = warehouseStock?.offlineMinStock || 0
            const webStock = st.onHandQty - st.onReservedQty - offlineMinStock
            if (webStock > 0) {
              result = false
              break
            }
          }
        }
      }
      return result
    },
    previewImg (img) {
      this.currentImgPreview = img
      this.previewEnabled = !!this.currentImgPreview
    },
    queryTags () {
      const result = ConvertQueryStringToArray(this.$route.query)

      this.query.search = result.search ? result.search : ''
      this.query.tags = result.tags ? result.tags : []
      this.query.tagOperation = result.tagOperation ? result.tagOperation : 'OR'
      this.query.brand = result.brand ? result.brand : 'all'
      this.query.collection = result.collection ? result.collection : ''
    },
    searchProduct () {
      this.isLoadComplete = false
      this.query.page = 0
      this.getting = false

      this.products = []
      this.$router.push({
        name: 'Products',
        query: {
          search: this.query.search,
          tags: this.query.tags || [],
          tagOperation: this.query.tagOperation,
          brand: this.query.brand,
          collection: this.query.collection
        }
      }).catch((error) => {
        console.error(error)
      })
      this.fetchProducts()
    },
    async fetchProducts () {
      this.getting = true
      if (this.query.page < this.totalPage) {
        this.query.page++
        try {
          this.queryTags()

          const { data } = await ProductService.getProducts({
            page: this.query.page,
            limit: this.query.limit,
            search: this.query.search,
            tags: this.query.tags,
            tagOperation: this.query.tagOperation,
            brand: this.query.brand,
            collection: this.query.collection
          })
          this.totalPage = data.pages
          this.totalProduct = data.total
          this.products.push(...data.results)
        } catch (error) {
          console.error('fetchProducts', error)
          this.setErrorPage(error.code)
        }
      }
      this.isLoadComplete = this.query.page >= this.totalPage
      this.getting = false
    },
    handleScroll (event) {
      const e = event.target.scrollingElement
      const scrollY = e.scrollHeight - e.clientHeight - 1000
      if (e.scrollTop >= scrollY && !this.getting) {
        this.fetchProducts()
      }
    },
    async exportProductsToCSV () {
      try {
        this.loading = true

        const limit = 1000
        let rounds = Math.ceil(this.totalProduct / limit)
        rounds = new Array(rounds).fill(0).map((item, index) => index)

        for await (const round of rounds) {
          const { data } = await ProductService.getProducts({
            page: round + 1,
            limit: 1000,
            search: this.query.search,
            tags: this.query.tags,
            tagOperation: this.query.tagOperation,
            brand: this.query.brand,
            collection: this.query.collection
          })

          const products = data.results

          const items = []
          products.forEach((product) => {
            for (const sku of product.skus) {
              items.push({
                'code': product.model,
                'name': product.name,
                'description': product.description,
                'variant1': 'color',
                'variant2': 'size',
                'brand': product.brand,
                'photo1': product.photoUrls[0] || '',
                'photo2': product.photoUrls[1] || '',
                'photo3': product.photoUrls[2] || '',
                'photo4': product.photoUrls[3] || '',
                'photo5': product.photoUrls[4] || '',
                'sku.code': sku.code,
                'sku.barcode': sku.code,
                'sku.variant1': sku.color?.name || '',
                'sku.variant2': sku.size?.name || '',
                'sku.cost': sku.price,
                'sku.price': sku.price
              })
            }
          })

          const options = {
            filename: `Products-${round + 1}`,
            showLabels: true,
            useKeysAsHeaders: true
          }

          const csvExporter = new ExportToCsv(options)
          csvExporter.generateCsv(items)
        }
      } catch (error) {
        console.error('fetchProductsToExport', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
