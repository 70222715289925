import { render, staticRenderFns } from "./ImgModalPreview.vue?vue&type=template&id=45fc479c&scoped=true"
import script from "./ImgModalPreview.vue?vue&type=script&lang=js"
export * from "./ImgModalPreview.vue?vue&type=script&lang=js"
import style0 from "./ImgModalPreview.vue?vue&type=style&index=0&id=45fc479c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45fc479c",
  null
  
)

export default component.exports